import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'

const facebookEnabled = false
const showBlog = false

const IndexPage = ({ data }) => (
  <Layout toggleTopNav={true}>
    <>
      <header className="home">
        <div className="container-fluid">
          <div className="bg-wrapper">
            <span className="bg fader"></span>
            <br />
            <img className="logo" src="//content.nanini.be/wp-content/uploads/2017/04/Homepage-Welkom-bij-Nanini.png" alt="Nanini" />
            <h1 className="text-hide">Welkom bij Nanini</h1>
          </div>
        </div>
      </header>

      <section className="bg">
        {showBlog && (
          <div id="blog" className="container">
            {data.allWordpressPost.edges[0].node && (
              <div className="row">
                <div className="col-lg-4 col-md-3 col-image">
                  <div className="image-wrapper">
                    <img src={data.allWordpressPost.edges[0].node.better_featured_image.source_url} className="img-fluid img-block" alt={data.allWordpressPost.edges[0].node.title} />
                    <Link className="marker circle circle-lg d-none d-md-block" to={`/blog/`}>blog</Link>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-text">
                  <h3 className="subtitle">{data.allWordpressPost.edges[0].node.wps_subtitle}</h3>
                  <h2 className="title primary mx-2">{data.allWordpressPost.edges[0].node.title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: data.allWordpressPost.edges[0].node.excerpt }}></div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="container mt-3">
          <div className="row">
            <div className="col-12 col-xl-4">
              <div className="card card-brands card-bg card-bg-no-border eqheight" style={{ height: '456px' }}>
                <div className="card-block">
                  <h2 className="title mb-2">Ontdek meer dan 100 merken bij Nanini!</h2>
                  <p>Ti sento, Mi moneda, Michael Kors, Kemira, Leethal Kalmanson, Biba, Melano, TOV, Rebecca, Diesel, Orage, Qudo, Pandora</p>
                  <p>
                    <Link className="" to={`/ons-aanbod/`}>Lees meer…</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-5 col-lg-7">
              <div className="card card-news card-no-border">
                <div className="card-block">
                  <h2 className="title primary mb-2">What’s new?</h2>
                  <ul className="news">

                    {data.allWordpressWpNews.edges.slice(0, 4).map(({ node }) => (
                      <li className="news-item" key={`news-${node.wordpress_id}`}>
                        <Link to={`/nieuws/${node.slug}/`} dangerouslySetInnerHTML={{ __html: node.title }}></Link>
                      </li>
                    ))}

                    <li className="">
                      <Link className="" to={`/nieuws/`}>Alle nieuwsitems…</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 col-lg-5">
              <div className="card card-bg3 text-xs-center eqheight" style={{ height: '456px' }}>
                <div className="card-block">
                  <h3>50% korting op alles bij</h3>
                  <p>
                    <Link className="" to={`/ons-aanbod/`}>
                      <br />
                      <img className="img-fluid" src="//content.nanini.be/wp-content/uploads/2017/04/Logo-Nanini-Outlet.png" alt="Nanini Outlet" />
                      <br />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="separatorline2"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card card-about card-no-border">
                <div className="card-block">
                  <div className="row">
                    <div className="col-lg-8 col-md-6 container-about">
                      <h2 className="title primary mb-2">Wie zijn wij?</h2>
                      <p>Nanini is een eigentijdse accessoirewinkel in het hartje van Turnhout, met must-have fantasiejuwelen en hippe horloges. Onze jarenlange ervaring vertaalt zich immers naar unieke collecties en kwalitatieve materialen. Bovendien hebben we een getraind team klaarstaan om voor élke klant het gepaste accessoiresetje te vinden!</p>
                      <p>
                        <Link className="" to={`/over-ons/`}>Lees meer…</Link>
                      </p>
                      {facebookEnabled && (
                        <p>
                          <img src="//content.nanini.be/wp-content/uploads/2017/04/Homepage-Volg-ons-op-Facebook.png" id="followfacebook" className="d-none d-lg-block" alt="Volg ons op Facebook" />
                        </p>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-6 container-about-image">
                      <img className="img-fluid img-block" src="//content.nanini.be/wp-content/uploads/2017/04/aboutus.jpg" alt="Wie zijn wij?" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {facebookEnabled && (
        <section className="mt-3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <img src="//content.nanini.be/wp-content/uploads/2017/04/Homepage-Volg-ons-op-Facebook.png" alt="Volg ons op Facebook" id="followfacebook" className="d-lg-none" />
              </div>
              <div className="col-12">

                <div className="row row-nomargin row-dotted row-primary circle-wrapper">
                  <a className="circle circle-float circle-float-topleft circle-md facebook d-none d-md-block" href="https://www.facebook.com/nanini.turnhout" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-facebook-official fa-2x"></i>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </section>
      )}
    </>
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
  	allWordpressWpNews {
      edges {
        node {
          id
          wordpress_id
          title
          date(formatString: "MMMM DD, YYYY")
          slug
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          id
          wordpress_id
          title
          wps_subtitle
          better_featured_image {
            source_url
          }
          excerpt
          slug
          link
        }
      }
    }
  }
`
